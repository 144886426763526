<template>
  <div class="users">
    <!-- 卡片视图区域 -->
    <el-card class="box-card">
      <div class="filter">
        <el-form :model="queryInfo" :inline=true ref="userForm">
          <el-form-item prop="shopName" label="店铺名称">
            <el-input v-model="queryInfo.shopName" placeholder="店铺名称"></el-input>
          </el-form-item>

          <el-form-item prop="username" label="用户名">
            <el-input v-model="queryInfo.username" placeholder="用户名"></el-input>
          </el-form-item>

          <el-form-item label="状态" prop="status">
            <el-select v-model="queryInfo.status" placeholder="请选择状态">
              <el-option v-for="item in statusArr" :key="item.key" :label="item.label" :value="item.key">
                <span style="font-weight: bold;">{{ item.label }}</span>
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="电商平台" prop="shopType">
            <el-select v-model="queryInfo.shopType" placeholder="选择电商平台">
              <el-option v-for="item in optionImg" :key="item.url" :label="item.label" :value="item.value">
                <img :src="item.url" style="height: 28px;">
                <span style="font-weight: bold;">{{ item.label }}</span>
              </el-option>
            </el-select>
        </el-form-item>

        <el-form-item prop="parentId" label="上级用户">
            <el-input v-model="queryInfo.parentId" placeholder="输入店铺管理员ID"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="getList(1)">查询</el-button>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="resetSearch">重置</el-button>
          </el-form-item>

          <el-form-item>
            <el-button type="info"  @click="addUser">用户添加</el-button>
          </el-form-item>

        </el-form>
      </div>
      <el-table v-loading="listLoading" :data="userData.userList" size="small" stripe border>
        <el-table-column prop="id" label="id" align="center"></el-table-column>
        <el-table-column prop="username" label="用户名" align="center"></el-table-column>
        <el-table-column prop="password" label="密码" align="center"></el-table-column>
        <el-table-column prop="apiKey" label="apiKey" align="center"></el-table-column>
        <el-table-column prop="shopName" label="店铺名称" align="center"></el-table-column>
        <el-table-column prop="from" label="开始时间" align="center">
          <template v-slot="scope">
            {{ scope.row.from * 1000 | dataFormat }}
          </template>
        </el-table-column>
        <el-table-column prop="to" label="结束时间" align="center">
          <template v-slot="scope">
            {{ (scope.row.to + 1 - 60 * 60 * 24) * 1000 | dataFormat('yyyy-mm-dd') }}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="生效状态" align="center">
          <template v-slot="scope">
            <el-tag size="small" :type="statusMap[scope.row.status].tag">{{ statusMap[scope.row.status].text }}</el-tag>
          </template>
        </el-table-column>

        <el-table-column prop="shopState" label="官方状态" align="center">
          <template v-slot="scope">
            <el-tag size="small" :type="scope.row.shopState === 'OPEN'
        ? 'success' : 'warning'">{{ scope.row.shopState === 'OPEN'
        ? '已开通' : '未开通' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="parentId" label="parentId" align="center"></el-table-column>
        <el-table-column prop="role" label="角色">
          <template v-slot="scope">
            {{ scope.row.role === 3 ? '店铺管理员' : '普通用户' }}
          </template>
        </el-table-column>
        <el-table-column prop="shopType" label="店铺类型" align="center"></el-table-column>
        <el-table-column label="操作" width="120px" align="center">
          <template v-slot="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini"  style="width: 80px; margin-top: 10px; margin-left: 10px;" @click="editUser(scope.row)">修改</el-button>
            <el-button type="danger" icon="el-icon-delete"  size="mini" style="width: 80px; margin-top: 10px"  @click="deleteUsers(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination @current-change="handleCurrentChange" :current-page="queryInfo.page"
        @size-change="handleSizeChange" :page-size="queryInfo.pageSize" :page-sizes="[20, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper" :total="userData.total">
      </el-pagination>
    </el-card>
    <single-dialog ref="singleDialog" @update="getList(1)"></single-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import singleDialog from './single.vue'
export default {
  name: 'Users',
  components: {
    singleDialog
  },
  data() {
    return {
      queryInfo: {
        username: '',
        shopName: '',
        status: '',
        shopType: '',
        parentId: '',
        page: 1,
        pageSize: 20
      },
      statusArr: [
        {
          key: 1,
          label: '未生效'
        },
        {
          key: 2,
          label: '生效中'
        },
        {
          key: 3,
          label: '已过期'
        }
      ],

      listLoading: false,
      userData: {
        userList: [],
        total: 0
      },
      statusMap: {
        1: {
          tag: 'warning',
          text: '未生效'
        },
        2: {
          tag: 'success',
          text: '生效中'
        },
        3: {
          tag: 'danger',
          text: '已过期'
        }
      },
      optionImg: [
        {
          label: '全部',
          value: 'all',
          url: ''
        },
        {
          label: 'worten',
          value: 'worten',
          url: require('../../assets/logo-worten.png')
        },
        {
          label: 'fnac',
          value: 'fnac',
          url: require('../../assets/logo-fnac.png')
        },
        {
          label: 'catch',
          value: 'catch',
          url: require('../../assets/logo-catch.png')
        },
        {
          label: 'eprice',
          value: 'eprice',
          url: require('../../assets/logo-eprice.png')
        },
        {
          label: 'fyndiq',
          value: 'fyndiq',
          url: require('../../assets/logo-fyndiq.png')
        },
        {
          label: 'onbuy',
          value: 'onbuy',
          url: require('../../assets/logo-onbuy.png')
        },
        {
          label: 'rdc',
          value: 'rdc',
          url: require('../../assets/logo-rdc.png')
        },
      ],
    }
  },
  created() {
    this.queryInfo = {
      username: '',
      shopName: '',
      page: 1,
      pageSize: 20
    }
    this.getList()
  },
  methods: {
    ...mapActions(['getUserList', 'deleteUser']),
    // 时间戳转为日期
    // 添加用户
    addUser() {
      this.$refs.singleDialog.open({})
    },
    // 修改用户
    editUser(obj) {
      this.$refs.singleDialog.open(obj)
    },
    deleteUsers(obj) {
      this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.deleteUser({ id: obj.id })
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.getList()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // // 表格
    // 请求列表
    async getList(page = 1, pageSize = 20) {
      try {
        this.listLoading = true
        this.queryInfo.page = page
        this.queryInfo.pageSize = pageSize
        let data = await this.getUserList(this.queryInfo);
        this.userData.userList = data.list
        this.userData.total = data.count
      } catch (e) {
        this.$message.error(e)
      } finally {
        this.listLoading = false
      }
    },
    // 监听 pagesize 改变事件 每页显示的个数
    handleSizeChange(newSize) {
      // console.log(newSize)
      this.getList(1, newSize)
    },
    // 监听 页码值 改变的事件 当前页面值
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getList(newPage, this.queryInfo.pageSize)
    },
    resetSearch() {
      this.$refs.userForm.resetFields()
      this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
.el-table {
  margin-top: 15px;
}

.filter {
  display: flex;
  align-items: center;
}

.el-input {
  width: 120px !important;
}
</style>
